// 合法账号
export function isvalidUsername(str) {
  return str.length >= 6
}
// 合法用户名
export function isvalidUserName(str) {
  return str.length >= 6
}
// 合法手机号码
export function isvalidPhone(str) {
  const reg = /^1[3456789]\d{9}$/
  return reg.test(str)
}
// 合法短信验证码
export function isvalidSms(str) {
  const reg = /^\d{6}$/
  return reg.test(str)
}
// 合法短信验证码
export function validEmail(str) {
  const reg = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
  return reg.test(str)
}
// 合法密码
export function isvalidPwd(str) {
  const reg = /^\w{6,16}$/
  return reg.test(str)
}
// 合法正整数
export function isvalidPosNum(num) {
  return num > 0
}
// 合法金额
export function isvalidMoney(num) {
  return true
}
//合法钱
export function isvalidShowMoney(num){
  // const reg = /((^[1-9]\d*)|^0)(\.\d{1,2}){0,1}$/
  // return reg.test(num)
  return num > 0
}
/* 合法uri*/
export function validateURL(textval) {
  const urlregex = /^(https?|ftp):\/\/([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9][0-9]?)(\.(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[1-9]?[0-9])){3}|([a-zA-Z0-9-]+\.)*[a-zA-Z0-9-]+\.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(:[0-9]+)*(\/($|[a-zA-Z0-9.,?'\\+&%$#=~_-]+))*$/
  return urlregex.test(textval)
}

/* 小写字母*/
export function validateLowerCase(str) {
  const reg = /^[a-z]+$/
  return reg.test(str)
}

/* 大写字母*/
export function validateUpperCase(str) {
  const reg = /^[A-Z]+$/
  return reg.test(str)
}

/* 大小写字母*/
export function validatAlphabets(str) {
  const reg = /^[A-Za-z]+$/
  return reg.test(str)
}
const MoneyTest = /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/;
/*验证是否企业统一社会信用码*/
export function isCompanyUnicode( str ){
  let reg = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g;
  return reg.test( str );
}

//名字至少两位
export function isRegName(str){
let reg = /^[u4e00-u9fa5aa-zA-Z]{2,}$/;
return reg.test(str)
}
//企业名字至少五位
export function isRegCompany(str){
  let reg = /^[u4e00-u9fa5aa-zA-Z]{5,}$/;
  return reg.test(str)
}
//判断字符长度
export function getStrLen(str){
  var len=str.length;
  for(var i=0;i<len;i++){
    var charCode=str.charCodeAt(i);
    if (charCode >= 0xD800 && charCode <= 0xDBFF){
      len--;
      i++;
    }
  }
  return len;
}
