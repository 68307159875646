import Vue from 'vue'
import VueRouter from "vue-router";

Vue.use(VueRouter)
import Home from '@/pages/wangshangdai/customer/index'


//重写repalce和push
let originPush = VueRouter.prototype.push
VueRouter.prototype.push = function (location, resolve, reject) {
    if (resolve && reject) {
        originPush.call(this, location, resolve, reject)
    } else {
        originPush.call(this, location, () => {
        }, () => {
        })
    }
}


let originReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function (location, resolve, reject) {
    if (resolve && reject) {
        originReplace.call(this, location, resolve, reject)
    } else {
        originReplace.call(this, location, () => {
        }, () => {
        })
    }
}
const router= new VueRouter({
    mode:'history',
    routes: [{
        path: '/home',
        name:'home',
        component: Home,
        meta: {
            show: true,
            title:'云卡科技-全国卡车维修联盟互联网平台'
           //  title:'云卡科技官网'
        }
    },
        // //云卡系统
        // {
        //     path: '/system',
        //     name:'system',
        //     component: System,
        //     meta: {
        //         show: true,
        //         title:'云卡系统'
        //         // title:'云卡科技官网'
        //
        //     }
        // },
        //货车之家
        {
            path: '/truckHome',
            meta: {
                title: '货车之家',
                content: {keywords: '货车之家', description: '货车之家，给你更优质的服务！'}
            },
            hidden: true,
            component: () => import('@/pages/truckHome/home/index'),
        },
        // //云卡联盟
        {
            path: '/wangshangdai',
            meta: {
                title: '云卡网商贷',
                content: {keywords: '云卡、网商贷', description: '云卡网商贷：你修车我买单，现金流不再紧张！'}
            },
            hidden: true,
            component: () => import('@/pages/wangshangdai/customer/index'),
        },
        {
            path: '/about',
            meta: {title: '关于', content: {keywords: '描述'}},
            component: () => import('@/pages/wangshangdai/customer/about'),
            hidden: true
        },
        {
            path: '/detail',
            meta: {title: '产品详情'},
            component: () => import('@/pages/wangshangdai/customer/detail'),
            hidden: true
        },
        {
            path: '/result',
            meta: {title: '提交成功'},
            component: () => import('@/pages/wangshangdai/customer/result'),
            hidden: true
        },
        {
            path: '/apply',
            meta: {title: '申请云卡e付通'},
            component: () => import('@/pages/wangshangdai/customer/apply'),
            hidden: true
        },
        {
            path: '/wangshangdaiShop',
            meta: {
                title: '云卡门店',
                content: {keywords: '云卡门店、网商贷', description: '云卡网商贷：你修车我买单，现金流不再紧张！'}
            },
            component: () => import('@/pages/wangshangdai/shop/index'),
            hidden: true
        },
        {
            path: '/shopResult',
            meta: {title: '提交成功'},
            component: () => import('@/pages/wangshangdai/shop/result'),
            hidden: true
        },
        {
            path: '/shopDetail',
            meta: {title: '门店详情'},
            component: () => import('@/pages/wangshangdai/shop/detail'),
            hidden: true
        },
        //重定向，在项目跑起来的时候，访问“/”,立马让他重定向到首页
        {path: '/', redirect: '/wangshangdai'},

    ]
})
export default router
