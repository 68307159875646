import Vue from 'vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import store from './store'

Vue.use(Vant);
import router from "../router"
import App from './App.vue'
import 'vue-layer-mobile/need/layer.css'
import layer from 'vue-layer-mobile'
Vue.use(layer)
Vue.config.productionTip = false
Vue.config.ignoredElements.push('wx-open-launch-weapp')
//路由验证
router.beforeEach((to, from, next)=>{
  document.title = ('title' in to.meta) && to.meta.title ? to.meta.title : '云卡官网';
  next()

})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")

