import adminInterface from '@/interface/baseApi'
export function apply(data) {
  return adminInterface({
    url: '/wangshangdai/customer/apply',
    method: 'post',
    data
  })
}
export function applySendCode(data) {
  return adminInterface({
    url: '/wangshangdai/customer/applySendCode',
    method: 'post',
    data
  })
}
export function wxInitGet(data) {
  return adminInterface({
    url: '/wangshangdai/customer/getWxPageCfg',
    method: 'post',
    data
  })
}


