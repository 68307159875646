<template>
    <div id="app"  >
        <router-view ></router-view>
    </div>
</template>

<script>
    export default {
        name: 'app',
        methods: {
        }
    }
</script>

<style lang="less" >
    /*@import '~vux/src/styles/reset.less';*/
    //html{height:100vh;overflow:hidden}
    ////备注
    //body {
    //  background-color: #fbf9fe;
    //  height:100%;overflow:auto
    //}

</style>
